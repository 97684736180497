<template>
  <div>
    <h2 class="text-2xl font-bold">Device Financing Transactions</h2>

    <div class="grid grid-cols-2 mt-4 mb-4">
      <div class="col-span-1">
        <tabs :tabs="tabs" v-model="tab" />
      </div>
      <div class="col-span-1" />
    </div>

    <component
      :is="tab"
      :search-query="searchQuery"
      :search-field="$refs.searchField"
    />
  </div>
</template>

<script>
export default {
  components: {
    approved: () => import('./Approved'),
    pending: () => import('./Pending'),
    cancelled: () => import('./Declined'),
  },
  data() {
    return {
      searchQuery: '',
      tab: 'pending',
      tabs: [
        { name: 'pending', title: 'Pending' },
        { name: 'approved', title: 'Approved' },
        { name: 'cancelled', title: 'Cancelled' },
      ],
    };
  },
  watch: {
    tab(tab) {
      if (this.$route.query.tab !== tab) {
        this.$router.replace({ ...this.$route, query: { tab } });
      }
    },
  },
  mounted() {
    if (this.$route.query.tab?.match(/pending|cancelled|approved/)) {
      this.tab = this.$route.query.tab;
    }
  },
};
</script>
